window.playlists = {};
window.players = {
  collection: [],

  handleFairplayPrepareMessage: function (event, session) {
    return (
      "spc=" + event.messageBase64Encoded + "&assetId=" + session.contentId
    );
  },

  handleFairplayPrepareContentId: function (contentId) {
    var index = contentId.indexOf("skd://");

    if (index > -1) return contentId.substring(8, 40);

    throw (
      "Invalid Content ID format. The format of the Content ID must be the following: " +
      "skd://xxx where xxx is the Key ID in hex format."
    );
  },

  // Returns our custom config object
  c: function (player) {
    return player.getConfig()._config;
  },

  handlePlayerLoaded: function (player) {
    console.debug("Player loaded");

    $(window).trigger("video-player:loaded", player);
    this.c(player).isLoaded = true;
  },

  handlePlayerReady: function (player) {
    console.debug("Player ready");

    $(window).trigger("video-player:ready", player);
  },

  handlePlayerFinished: function (player, playerId) {
    if (window.playlists[playerId]) {
      var nextSource = window.playlists[playerId].shift();
      if (nextSource) {
        player.pause();
        player.load(nextSource).then(function () {
          player.play();
        });
      }
    }
  },

  handlePlayerPlaying: function (player) {
    console.debug("Player playing");

    window.player = player;

    if (player.getSource().watchUrl) {
      $.post(player.getSource().watchUrl);
    } else if (player.getConfig()._config.watchUrl) {
      $.post(player.getConfig()._config.watchUrl);
    }

    $(window).trigger("video-player:play", player);
  },

  handlePlayerErrored: function (player) {
    console.debug("Player errored");

    $(window).trigger("video-player:error", player);

    if (this.c(player).isLive) return;

    $("#" + this.c(player).errorId).removeClass("d-none");
  },

  togglePoster: function (player) {
    $("#" + this.c(player).posterId).addClass("d-none");
    $("#" + this.c(player).elementId).removeClass("d-none");
  },

  load: function (player) {
    if (this.c(player).isLoaded) return;

    this.togglePoster(player);
    player.load(this.c(player).loadConfig);

    this.handlePlayerLoaded(player);
  },

  init: function (player) {
    this.collection.push(player);

    var startAt = this.c(player).startAt;
    if (!startAt) return this.load(player);

    var timeUntil = moment(startAt).diff(moment());
    if (timeUntil <= 0) return this.load(player);

    var loader = this.load.bind(this);
    $("#" + this.c(player).posterId).on("click", function () {
      loader(player);
    });
    setInterval(function () {
      loader(player);
    }, timeUntil);
  },
};
