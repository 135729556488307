$(document).on("turbo:load", function () {
  font_sizes = [];

  for (let size = 8; size < 151; size++) {
    font_sizes[font_sizes.length] = size.toString();
  }

  font_names = [
    "Arial Black",
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "GT Planar Italic 45",
    "GT Planar Retalic 45",
    "GT Planar",
    "Helvetica Neue",
    "Helvetica",
    "Impact",
    "LiberationSans",
    "Lucida Grande",
    "Oswald",
    "Rajdhani",
    "Saira",
    "Tahoma",
    "Times New Roman",
    "TT Commons pro Thin",
    "TT Commons pro ExtraLight",
    "TT Commons pro Light",
    "TT Commons pro",
    "TT Commons pro Narrow",
    "TT Commons pro Medium",
    "TT Commons pro DemiBold",
    "TT Commons pro ExtraBold",
    "TT Commons pro Black",
    "TT Commons pro ExtraBlack",
    "Ubuntu",
    "Verdana",
  ];

  additional_fonts = ["GT-Walsheim", "Montserrat", "Wasa"];

  if (typeof document.body.dataset.googleFonts != "undefined") {
    additional_fonts = additional_fonts.concat(
      JSON.parse(document.body.dataset.googleFonts)
    );
  }

  font_names = font_names.concat(additional_fonts);

  $("textarea.wysiwyg").summernote({
    lang: "en-EN",
    minHeight: 200,
    maxHeight: 500,
    fontNames: font_names,
    fontNamesIgnoreCheck: additional_fonts,
    fontSizes: font_sizes,
    toolbar: [
      ["style", ["style"]],
      ["font", ["bold", "italic", "underline", "clear"]],
      ["fontname", ["fontname", "fontsize", "height"]],
      ["color", ["color"]],
      ["para", ["ul", "ol", "paragraph"]],
      ["table", ["table"]],
      ["insert", ["link", "picture", "video"]],
    ],
  });
});
